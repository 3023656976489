import React, { useState } from 'react'

// next components
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

// custom components

// custom functions
import { useViewport } from 'utils'

// images and icons
import LogoText01MobileIcon from 'assets/icons/logo-text-01-mobile.svg?component'
import InstagramLightGreenIcon from 'assets/icons/instagram-light-green.svg?component'
import DiscordLightGreenIcon from 'assets/icons/discord-light-green.svg?component'
import TwitterLightGreenIcon from 'assets/icons/twitter-light-green.svg?component'
import GithubLightGreenIcon from 'assets/icons/github-light-green.svg?component'
import FosterLogo from 'assets/logo-new.svg?component'

// styles
import styles from './index.module.scss'
import IconButtonWithSVG from 'components/Button/IconButtonWithSVG'

const PrimaryFooter = () => {
  const viewport = useViewport()
  const router = useRouter()

  return (
    <div className={styles.container} data-testid="footer-container">
      <div className={styles.border} />
      {viewport !== 'xs' && viewport !== 'sm' && viewport !== 'md' ? (
        <div className="g-container flex justify-between gap-4 z-10 py-[104px]">
          <div className="max-w-[347px] block justify-start">
            <div className="w-full cursor-pointer">
              <div className="flex items-center gap-[6px]" onClick={() => router.push('/')}>
                <FosterLogo className={styles.logoIcon} />
                <div className={styles.logoFont}>foster</div>
              </div>
            </div>

            <div className="flex flex-wrap mt-[40px] gap-5">
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_INSTAGRAM_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    iconWidth={20}
                    iconHeight={20}
                    icon={<InstagramLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_DISCORD_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    iconWidth={20}
                    iconHeight={20}
                    icon={<DiscordLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_TWITTER_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    iconWidth={20}
                    iconHeight={20}
                    icon={<TwitterLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_GITHUB_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    iconWidth={20}
                    iconHeight={20}
                    icon={<GithubLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
            </div>

            <div className={styles.greenText}>© 2024 Foster Marketplace. All Rights Reserved</div>
          </div>
          <div className="flex gap-[100px]">
            <div className="max-w-[347px] block justify-start">
              <div className={styles.title}>Sitemap</div>
              <div className={`${styles.linkText} !mt-[34px]`}>
                <Link href="/explore">Explore</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/dao">Governance</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/resources">Resources</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/stats">Leaderboards</Link>
              </div>
            </div>
            {/* <div className="max-w-[347px] block justify-start">
            <div className={styles.title}>About</div>
            <div className={`${styles.linkText} !mt-[49px]`}>
              <a target="_blank" href="https://about.fostermarketplace.app/" rel="noopener noreferrer">
                Foster Labs
              </a>
            </div>
            <div className={styles.linkText}>
              <a target="_blank" href="https://circus.fostermarketplace.app/" rel="noopener noreferrer">
                Turkey Circus
              </a>
            </div>           
            <div className={styles.linkText}>
              <a target="_blank" href={process.env.NEXT_PUBLIC_PRIVACY_POLICY} rel="noopener noreferrer">
                Privacy Policy
              </a>
            </div>
          </div> */}
            <div className="max-w-[347px] block justify-start">
              <div className={styles.title}>Resources</div>
              <div className={`${styles.linkText} !mt-[34px]`}>
                <a target="_blank" href="https://about.fostermarketplace.app/" rel="noopener noreferrer">
                  About us
                </a>
              </div>
              <div className={styles.linkText}>
                <a
                  target="_blank"
                  href="https://foster-marketplace.gitbook.io/docs/v/brand-and-design-library/"
                  rel="noopener noreferrer"
                >
                  Brand & Design
                </a>
              </div>
              {/* <div className={styles.linkText}>
              <a target="_blank" href={process.env.NEXT_PUBLIC_DEVELOPMENT} rel="noopener noreferrer">
                Development
              </a>
            </div> */}
              {/* <div className={styles.linkText}>
              <a target="_blank" href={process.env.NEXT_PUBLIC_COMMUNITY} rel="noopener noreferrer">
                Community
              </a>
            </div> */}
              <div className={styles.linkText}>
                <a href="mailto:admin@Fostermarketplace.app">Media Inquiries</a>
              </div>
              <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_TERMS_OF_USE} rel="noopener noreferrer">
                  Terms of Service
                </a>
              </div>
            </div>
          </div>
          {/*<div className="max-w-[347px] block justify-start">
             <div className={styles.title}>Stay Connected</div>
            <div className="mt-[29px]">
              <Subscriber value={email} placeholder="Email" onChange={value => setEmail(value)} onClick={() => {}} />
            </div> 
          </div>*/}
        </div>
      ) : (
        <div className="relative g-container flex flex-wrap justify-center gap-4 my-[60px] z-10">
          <div className="w-full flex flex-wrap justify-center">
            <div
              className="w-full flex items-center gap-[6px] justify-center cursor-pointer"
              onClick={() => router.push('/')}
            >
              <FosterLogo className={styles.logoIcon} />
              <div className={styles.logoFont}>foster</div>
            </div>
            {/* <div
              className="w-full mt-[70px] mr-[50px] flex justify-end cursor-pointer"
              onClick={() => handleThemeMode()}
            >
              {lightMode ? <LightDarkModeMobileIcon /> : <DarkLightModeMobileIcon />}
            </div> */}
          </div>
          <div className="w-full flex justify-around mt-[70px]">
            <div className="w-full block justify-center text-center">
              <div className={styles.title}>Sitemap</div>
              <div className={`${styles.linkText} !mt-[20px]`}>
                <Link href="/explore">Explore</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/dao">Governance</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/resources">Resources</Link>
              </div>
              <div className={styles.linkText}>
                <Link href="/stats">House Stats</Link>
              </div>
            </div>
            <div className="w-full block justify-center text-center">
              <div className={styles.title}>About</div>
              <div className={`${styles.linkText} !mt-[20px]`}>
                <a target="_blank" href="https://about.fostermarketplace.app/" rel="noopener noreferrer">
                  Foster Labs
                </a>
              </div>
              <div className={styles.linkText}>
                <a target="_blank" href="https://circus.fostermarketplace.app/" rel="noopener noreferrer">
                  Turkey Circus
                </a>
              </div>
              {/* <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_CAREERS} rel="noopener noreferrer">
                  Careers
                </a>
              </div> */}
              <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_TERMS_OF_USE} rel="noopener noreferrer">
                  Terms of Service
                </a>
              </div>
              <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_PRIVACY_POLICY} rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="w-full block justify-center text-center">
              <div className={styles.title}>Resources</div>
              <div className={`${styles.linkText} !mt-[23px]`}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_WHITEPAPER} rel="noopener noreferrer">
                  Whitepaper
                </a>
              </div>
              <div className={styles.linkText}>
                <a
                  target="_blank"
                  href="https://foster-marketplace.gitbook.io/docs/v/brand-and-design-library/"
                  rel="noopener noreferrer"
                >
                  Brand & Design
                </a>
              </div>
              {/* <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_DEVELOPMENT} rel="noopener noreferrer">
                  Development
                </a>
              </div> */}
              <div className={styles.linkText}>
                <a target="_blank" href={process.env.NEXT_PUBLIC_COMMUNITY} rel="noopener noreferrer">
                  Community
                </a>
              </div>
              <div className={styles.linkText}>
                <a href="mailto:admin@Fostermarketplace.app">Media Inquiries</a>
              </div>
            </div>
          </div>

          <div className="w-full block justify-center text-center mt-[42px]">
            {/* <div className={styles.stayTitle}>Stay Connected</div>
            <div className="mt-[26px] flex justify-center z-10">
              <Subscriber value={email} placeholder="Email" onChange={value => setEmail(value)} onClick={() => {}} />
            </div> */}
            <div className="flex flex-wrap mt-[44px] gap-[18px] justify-center">
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_INSTAGRAM_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    icon={<InstagramLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_DISCORD_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    icon={<DiscordLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_TWITTER_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    icon={<TwitterLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
              {/* <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_GITHUB_URL} rel="noopener noreferrer">
                  <IconButton backgroundColor="var(--social-bg-color)" icon={TelegramLightGreenIcon} onClick={() => {}} />
                </a>
              </div> */}
              <div>
                <a target="_blank" href={process.env.NEXT_PUBLIC_GITHUB_URL} rel="noopener noreferrer">
                  <IconButtonWithSVG
                    backgroundColor="var(--social-bg-color)"
                    icon={<GithubLightGreenIcon />}
                    onClick={() => {}}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.greenText}>© 2024 Foster Marketplace. All Rights Reserved</div>
        </div>
      )}
    </div>
  )
}

export default PrimaryFooter
